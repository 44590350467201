
import { Navigation, Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { useRouter } from "next/router"
import { useEffect, useState, useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Select from "react-select"
import OurPortfolioMobileCards from "./OurPortfolioMobileCards";
import { position } from "@chakra-ui/react";
import FadeIn from "../Animations/FadeIn";
const OurPortfolio = ({ Our_Portfolio }) => {
    const [slideNumber, setSlideNumber] = useState('0' + 1)
    const CustomStyles = {
        menu: (provided, state) => ({
            ...provided,
            background: '#292C33',
            color: 'white',
            padding: '0',
            borderRadius: '0',
            zIndex: '2',
            position: "absolute"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'white',
            textTransform: 'capitalize',
            margin: 0

        }),
        control: (provided, state) => ({
            ...provided,
            background: '#292C33',
            boxShadow: 'none',
            borderRadius: '0rem',
            minHeight: 'unset',
            transition: '1s all',
            border: "none",
            zIndex: '1',
            color: 'white',
            cursor: 'pointer',
            padding: '.75rem 1.5rem'
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected?"#fff":"transparent",
            color: state.isSelected&&"#000",
            '&:hover': {
                backgroundColor: !state.isSelected && 'rgba(255, 255, 255, 0.3)',
                color: !state.isSelected && '#fff'
            }
        }),

        singleValue: (provided, state) => ({
            ...provided,
            color: 'white',
            textTransform: 'capitalize'

        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: 0,

        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none',
        }),

    };
    const [options, setOptions] = useState()
    const [area, setArea] = useState()
    const { locale } = useRouter()
    //fill select options
    useEffect(() => {
        const selectOptions = []
        Our_Portfolio?.Project_Tabs?.data?.map(tab => selectOptions.push({
            label: tab?.attributes?.name,
            value: tab?.attributes?.name,
        }))
        setOptions(selectOptions)
    }, [Our_Portfolio])
    console.log(options , "options");
    //first state for area
    useEffect(() => {
        setArea(Our_Portfolio?.Project_Tabs?.data[0])
    }, [Our_Portfolio])

    //change area when select
    const handlingSelect = (option) => {
        const area = Our_Portfolio?.Project_Tabs?.data?.find(tab => tab?.attributes?.name == option)
        setArea(area)
        setSlideNumber('0' + 1)
    }
    return (
        <>
            <FadeIn customClass="portfolio-container-mobile">
                <div className="header light _eleX">
                    {Our_Portfolio?.Header}
                </div>
                <div className="select-container">
                    <label htmlFor={"portfolio-input"} style={{ display: "none" }} ></label>
                    <Select
                        isSearchable={false}
                        options={options}
                        onChange={(e) => { handlingSelect(e.value) }}
                        placeholder={area?.attributes?.name}
                        className="select-drop-down _eleX"
                        styles={CustomStyles}
                        instanceId={"search portfolio"}
                        id={'portfolio-input'}
                        aria-labelledby={'portfolio-input'}
                        aria-label={'portfolio-input'}
                    />
                </div>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={50}
                    breakpoints={{
                        768: {
                            slidesPerView: area?.attributes?.projects?.data?.length +
                                area?.attributes?.business_projects?.data?.length
                                + area?.attributes?.commercial_projects?.data?.length == 1 ? 1 : 1.5,
                            spaceBetween: 40,
                        },
                        1023: {
                            slidesPerView: 2.5,
                            spaceBetween: 20,
                        },
                    }}
                    // centeredSlides={area?.attributes?.projects?.data?.length +
                    //     area?.attributes?.business_projects?.data?.length
                    //     + area?.attributes?.commercial_projects?.data?.length == 1 ?true:false}
                    key={area?.attributes?.name}
                    style={{ direction: locale == "ar" ? "rtl" : "ltr" }}
                    modules={[Navigation, Pagination, EffectFade]}
                    navigation={{
                        nextEl: ".swiper-button-next-port",
                        prevEl: ".swiper-button-prev-port",
                    }}
                    className="swiper-portfolio-section"
                    onSlideChange={(swiper) => {
                        setSlideNumber('0' + (swiper.realIndex + 1))
                    }}
                    pagination={{
                        type: "progressbar",
                        el: ".swiper-portfolio-navigation",
                        clickable: true,
                        renderBullet: (index, className) => {
                            return '<span class="' + className + '">' + "</span>"
                        },
                    }}
                >
                    <div className="swiper-data">
                        {area?.attributes?.projects?.data?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <OurPortfolioMobileCards item={item} index={index} />
                            </SwiperSlide>
                        ))}
                        {area?.attributes?.business_projects?.data?.map((item, index) => (
                            <SwiperSlide key={index + "business"}>
                                <OurPortfolioMobileCards item={item} index={index + "business"} />
                            </SwiperSlide>
                        ))}
                        {area?.attributes?.commercial_projects?.data?.map((item, index) => (
                            <SwiperSlide key={index + "commercial"}>
                                <OurPortfolioMobileCards item={item} index={index + "commercial"} />
                            </SwiperSlide>
                        ))}
                    </div>
                </Swiper>
                    {area?.attributes?.projects?.data?.length +
                        area?.attributes?.business_projects?.data?.length
                        + area?.attributes?.commercial_projects?.data?.length > 1 &&
                        <div className='swiper-navigation-continer'>
                            <div className='navigation-container'>
                                <div className='slide-number-container bold'>
                                    <div>{slideNumber}</div>
                                    <div>{`0${area?.attributes?.projects?.data?.length + area?.attributes?.business_projects?.data?.length + area?.attributes?.commercial_projects?.data?.length}`}
                                    </div>
                                </div>
                                <div className='swiper-portfolio-navigation swiper-pagination-progressbar'></div>
                            </div>
                            <div className='arrows-container'>
                                <div className="swiper-button-prev swiper-button-prev-port  portfolio-arrow"></div>
                                <div className="swiper-button-next swiper-button-next-port portfolio-arrow"></div>
                            </div>
                        </div>}
            </FadeIn>
        </>
    )
}

export default OurPortfolio